<template>
    <div class="col">
        <div class="row">
            <div class="col-12 col-md-6 q-pa-xs q-pb-md">
                <glf-email-domain-suggestion
                    v-model="dados.email"
                    :domains="dominios"
                    :error="showEmailError"
                    error-message="E-mail inválido"
                />
            </div>
            <div class="col-12 col-md-6 q-pa-xs">
                <q-input
                    ref="telefone_celular"
                    square
                    filled
                    v-model="dados.telefone_celular"
                    label="Celular"
                    :rules="[
                        (val) => !!val || 'É necessário preencher este campo',
                        (val) => val.length >= 10 || 'Informe um telefone válido',
                    ]"
                    type="tel"
                    mask="(##) #########"
                    unmasked-value
                />
            </div>
        </div>
        <q-stepper-navigation>
            <q-btn
                id="btnProximo"
                class="q-ml-sm btn-proximo"
                color="primary"
                icon-right="navigate_next"
                @click="proximo"
                label="Próximo"
            />
        </q-stepper-navigation>
    </div>
</template>

<script>
import MixinMessages from '@/mixins/mixinMessages'
import DominioService from '@/services/dominio'

export default {
    name: 'stepContato',

    emits: ['proximo', 'update:contato'],

    mixins: [
        MixinMessages
    ],

    props: {
        contato: {
            type: Object,
            required: true
        },
        idManutencao: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dados: {
                email: '',
                telefone_celular: ''
            },
            dominios: [],
            showEmailError: false
        }
    },

    watch: {
        dados: {
            deep: true,
            handler(value) {
                this.validaEmail()
                this.$emit('update:contato', value)
            }
        },

        contato: {
            deep: true,
            handler(value) {
                this.dados = value
            }
        }
    },

    created() {
        this.buscaDominios()
    },

    methods: {
        validaEmail() {
            if (this.dados.email) {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!regex.test(this.dados.email)) {
                    this.showEmailError = true
                } else {
                    this.showEmailError = false
                }
            } else {
                this.showEmailError = false
            }
        },

        validaCampos() {
            this.validaEmail()
            this.$refs.telefone_celular.validate()

            const errors = [
                this.$refs.telefone_celular.hasError,
                this.showEmailError
            ]

            if (errors.includes(true)) {
                return false
            }
            return true
        },

        proximo() {
            if (this.validaCampos()) {
                this.$emit('proximo')
            }
        },

        buscaDominios() {
            this.showLoading("Buscando dominios de e-mail! Por favor, aguarde...")
            DominioService.get()
                .then(({ data }) => {
                    this.hideLoading()
                    this.dominios = data.sort((a, b) => {
                        if (a.prioridade === null && b.prioridade === null) {
                            return a.nome.localeCompare(b.nome);
                        } else if (a.prioridade === null) {
                            return 1;
                        } else if (b.prioridade === null) {
                            return -1;
                        } else {
                            const diffPrioridade = a.prioridade - b.prioridade;
                            return diffPrioridade !== 0 ? diffPrioridade : a.nome.localeCompare(b.nome);
                        }
                    }).map((dominio) => dominio.nome);
                })
                .catch((error) => {
                    this.hideLoading()
                    this.errorMessage('Erro ao buscar dominios de e-mail!', 'Por favor, tente novamente mais tarde.', error)
                })
        }
    }
}
</script>