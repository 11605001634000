<template>
    <q-modal-view
        id="modTermoPrivacidade"
        ref="modal"
        title="Termo de Privacidade"
        :maximized="true"
        :dark="$q.dark.isActive ? true : false"
        @fechar="$router.push('/')"
        @keydown.esc="$router.push('/')"
    >
        <q-card
            class="row scroll bg-white text-black q-ma-md"
            style="height:80vh;"
            flat
        >
            <q-card-section v-if="loading" class="col">
                <q-skeleton-loading />
            </q-card-section>
            <q-card-section>
                    <div v-html="contrato" style="overflow:auto; " class="q-pr-sm"/>
            </q-card-section>
        </q-card>
        <q-footer elevated class="bg-white row">
            <q-toolbar class="col q-gutter-y-sm">
                <q-toggle
                    v-model="contratoAceite"
                    color="primary"
                    keep-color
                />
                <span class="q-subtitle text-black text-center"> Li e aceito os termos de privacidade disponiveis acima</span>
            </q-toolbar>
            <q-btn :disable="!contratoAceite" class="bg-primary text-white q-ma-sm" @click="validaContrato">Avançar</q-btn>
        </q-footer>
    </q-modal-view>
</template>

<script>

import ParametrosService from '@/services/parametros'
import QSkeletonLoading from '@/components/shared/QSkeletonLoading'
import MixinMessages from '@/mixins/mixinMessages'
import QModalView from '@/components/shared/QModalView'
import ManutencaoClienteService from '../../services/manutencao'
import store from '@/store/index'

export default {
    name: 'QTermo',

    components: {
        QSkeletonLoading,
        QModalView
    },

    emits: ['termoAceito'],

    mixins: [
        MixinMessages
    ],

    props: {
        estilo: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            contrato: null,
            empresa: {},
            loading: true,
            contratoAceite: false,
            dados_usuario: {
                id_cliente: store.getters.getUsuarioLogado.id,
                codigo_cliente: store.getters.getUsuarioLogado.codigo,
                loja: store.getters.getUsuarioLogado.id_loja,
                rede: String(process.env.VUE_APP_EMPRESA).toUpperCase() === 'POMP' ? 1 : 4,
                cpf: store.getters.getUsuarioLogado.cpf,
                nome: store.getters.getUsuarioLogado.nome,
                responsavel_abertura: 'AREA_CLIENTE',
                origem_manutencao: 'ONLINE'
            }
        }
    },

    methods: {
        $_getContrato() {
            this.loading = true
            ParametrosService.getParametro('MANUTENCAO.HTML_TERMO_PRIVACIDADE')
                .then(({ data }) => {
                    this.contrato = data[0].valor
                    this.loading = false
                })
                .catch(e => {
                    this.loading = false
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao buscar o termo',
                        error: e
                })
            })
        },

        validaContrato(){
            if(this.contratoAceite){
                this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
                ManutencaoClienteService.post({...this.dados_usuario}).then(({ data })=> {
                    this.$emit('termoAceito', data.id)
                }).catch(err => {
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro criar manutençao de cliente',
                        error: err
                    })
                    this.$router.push('/')
                })
                .finally(() => {
                    this.hideLoading()
                    this.close()
                })
            }
        },

        open() {
            this.$_getContrato()
            this.$refs.modal.show()
        },

        close() {
            this.$refs.modal.hide()
        }


    }
}
</script>

<style>

</style>
