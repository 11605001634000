<template>
    <div class="row-12">
        <div class="row-12">
            <q-banner-mensagem
                :mensagem="`Enviamos um SMS com o token para o celular ${telefoneCelular}`"
                classe="q-pa-md"
                cor_texto="text-white"
                tipo="bg-primary"
                :uppercase="false"
            >
            </q-banner-mensagem>
        </div>
        <div class="row-12 q-mt-sm">
            <div class="token-container q-mt-md">
                <div class="token-inputs">
                    <q-input
                        ref="tokenItem0"
                        class="token-item"
                        type="tel"
                        mask="#"
                        v-model="tokenNumbers[0]"
                        @keyup="proximoCampo(1)"
                        filled
                    />
                    <q-input
                        ref="tokenItem1"
                        class="token-item"
                        type="tel"
                        mask="#"
                        v-model="tokenNumbers[1]"
                        @keyup="proximoCampo(2)"
                        filled
                    />
                    <q-input
                        ref="tokenItem2"
                        class="token-item"
                        type="tel"
                        mask="#"
                        v-model="tokenNumbers[2]"
                        @keyup="proximoCampo(3)"
                        filled
                    />
                    <q-input
                        ref="tokenItem3"
                        class="token-item"
                        type="tel"
                        mask="#"
                        v-model="tokenNumbers[3]"
                        @keyup="proximoCampo(4)"
                        filled
                    />
                    <q-input
                        ref="tokenItem4"
                        class="token-item"
                        type="tel"
                        mask="#"
                        v-model="tokenNumbers[4]"
                        @keyup="proximoCampo(5)"
                        filled
                    />
                    <q-input
                        ref="tokenItem5"
                        class="token-item"
                        type="tel"
                        mask="#"
                        v-model="tokenNumbers[5]"
                        @keyup="proximoCampo(6)"
                        filled
                    />
                </div>
            </div>
            <div class="row justify-center">
                <q-btn
                    class="col-12 col-md-4 q-mt-lg"
                    :color="submitting ? 'grey-7' : 'primary'"
                    :label="`${submitting ? mensagemAguardeTempo : 'Reenviar código de acesso'}`"
                    :disabled="submitting"
                    @click="reenviarCodigoAcesso"
                />
            </div>
        </div>
        <q-stepper-navigation>
            <div class="row justify-between">
                <q-btn
                    id="btnVoltar"
                    class="q-ml-sm btn-proximo"
                    color="primary"
                    flat
                    icon="navigate_before"
                    @click="voltar"
                    label="Voltar"
                />
                <q-btn
                    id="btnProximo"
                    class="q-ml-sm btn-proximo"
                    color="primary"
                    icon-right="navigate_next"
                    @click="proximo"
                    label="Próximo"
                />
            </div>
        </q-stepper-navigation>
    </div>
</template>

<script>
import MixinMessages from '@/mixins/mixinMessages'
import QBannerMensagem from '@/components/shared/QBannerMensagem'
import ManutencaoClienteService from '@/services/manutencao'

export default {
    name: 'stepToken',

    components: {
        QBannerMensagem
    },

    mixins: [
        MixinMessages
    ],

    emits: [
        'proximo',
        'voltar',
        'update:token'
    ],

    props: {
        value: {
            type: Object,
            required: true
        },
        telefoneCelular: {
            type: String,
            required: true
        },
        idManutencao: {
            type: String,
            required: true
        },
        tempoReenvioSms: {
            type: Number,
            default: 60,
            required: true
        }
    },

    mounted() {
        this.warningMessage({
            title: 'Atenção',
            message: 'Caso não tenha recebido o token, você pode solicitá-lo<br/>novamente clicando no botão <strong>“Reenviar Token”</strong>,<br/>após o tempo estipulado.',
            duration: 5000
        })
        this.iniciarContadorSms(this.tempoReenvioSms) 
    },

    data() {
        return {
            token: null,
            tokenNumbers: [null, null, null, null, null, null],
            enviando: false,
            mensagemAguardeTempo: 'Enviando SMS ...',
            submitting: true
        }
    },

    watch: {
        token: {
            deep: true,
            handler(value) {
                console.log(value);
                this.$emit('update:token', value)
            }
        },

        tokenNumbers: {
            handler: function (val) {
                this.token = val.join('')
            },
            deep: true
        }
    },

    methods: {
        reenviarCodigoAcesso() {
            this.submitting = true
            this.showLoading({ message: 'Reenviando seu Token via SMS! Por favor, Aguarde!' })
            ManutencaoClienteService
                .postReenviaToken(this.idManutencao)
                .then(() => {
                    this.successMessage({
                        title: 'SMS enviado',
                        message: 'Você receberá em breve o código de acesso por SMS!'
                    })
                })
                .catch(e => {
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Não conseguimos reenviar o código de acesso para você, tente novamente mais tarde!',
                        error: e
                    })}
                )
                .finally(() => {
                    this.iniciarContadorSms(this.tempoReenvioSms)
                    this.hideLoading()
                })
        },

        iniciarContadorSms(totalSegundos) {
            let tempoEsperaSms = totalSegundos

            const smsTimer = setInterval(() => {
                const minutos = Math.floor(tempoEsperaSms / 60)
                const segundos = tempoEsperaSms % 60

                if (tempoEsperaSms <= 0) {
                    clearInterval(smsTimer)
                    this.submitting = false
                }

                const segundosFormatados = segundos < 10 ? `0${segundos}` : segundos
                tempoEsperaSms--

                this.mensagemAguardeTempo = `Você poderá reenviar o SMS em: ${minutos}:${segundosFormatados}`
            }, 1000)
        },

        proximoCampo(index) {
            if (index < 6) {
                this.$refs[`tokenItem${index}`].focus()
            }
        },

        proximo() {
            this.$emit('proximo')
        },

        voltar() {
            this.$emit('voltar')
        }
    }
}
</script>

<style lang="scss">
    .token-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 8px;
    }

    .token-inputs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 17px;
    }

    .token-item {
        width: 40px;
        height: 50px;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid var(--q-color-primary);
        text-align: center;
        font-size: 24px;
    }
</style>