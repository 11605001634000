import Services from './services'

class NaturezaProfissionalServices extends Services {
    constructor() {
        super({
            url: '/natureza-profissional',
            config: {
                baseURL: process.env.VUE_APP_API_PROPOSTA_CREDITO,
                headers: {
                    'Api-Version': process.env.VUE_APP_API_PROPOSTA_CREDITO_VERSION,
                    Authorization: process.env.VUE_APP_API_PROPOSTA_CREDITO_TOKEN
                },
                timeout: 60 * 1000
            }
        })
    }
}
export default new NaturezaProfissionalServices()
