<template>
    <div class="row">
        <div class="col-12 col-md-3 q-pa-xs">
            <q-input
                ref="endereco_cep"
                v-model="dados.endereco_cep"
                label="CEP *"
                mask="##.###-###"
                unmasked-value
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                    (val) => val.length >= 8 || 'Este campo deve ter 8 caracteres',
                ]"
                maxlength="11"
                counter
            />
        </div>
        <div class="col-12 col-md-9 q-pa-xs">
            <q-input
                ref="endereco_cidade"
                v-model="dados.endereco_cidade"
                label="Cidade *"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                ]"
                maxlength="30"
                counter
            />
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 q-pa-xs">
            <q-input
                ref="endereco_logradouro"
                v-model="dados.endereco_logradouro"
                label="Logradouro *"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                ]"
                maxlength="40"
                counter
            />
        </div>
        <div class="col-12 col-md-3 q-pa-xs">
            <q-input
                ref="endereco_numero"
                v-model="dados.endereco_numero"
                class="col-2"
                label="Número *"
                @keyup="() => { dados.endereco_numero = dados.endereco_numero.replace(/[^0-9]+/g, '') }"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo'
                ]"
                maxlength="7"
                counter
            />
        </div>
        <div class="col-12 col-md-3 q-pa-xs">
            <q-input
                ref="endereco_bairro"
                v-model="dados.endereco_bairro"
                label="Bairro *"
                square
                filled
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo'
                ]"
                lazy-rules
                maxlength="40"
                counter
            />
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-3 q-pa-xs">
            <q-select
                ref="endereco_uf"
                v-model="dados.endereco_uf"
                :options="estadoOptions"
                label="Estado *"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo'
                ]"
            />
        </div>
        <div class="col-12 col-md-9 q-pa-xs">
            <q-input
                class="col"
                v-model="dados.endereco_complemento"
                square
                filled
                label="Complemento"
                lazy-rules
                maxlength="100"
                counter
            />
        </div>
    </div>
    <q-stepper-navigation>
        <div class="row justify-between">
            <q-btn
                id="btnVoltar"
                class="q-ml-sm btn-proximo"
                color="primary"
                flat
                icon="navigate_before"
                @click="voltar"
                label="Voltar"
            />
            <q-btn
                id="btnProximo"
                class="q-ml-sm btn-proximo"
                color="primary"
                icon-right="navigate_next"
                @click="proximo"
                label="Próximo"
            />
        </div>
        </q-stepper-navigation>
</template>

<script>
import MixinMessages from '@/mixins/mixinMessages'
import CEPService from '@/services/cep'

export default {
    name: 'stepEndereco',

    mixins: [
        MixinMessages
    ],

    emits: ['proximo', 'voltar', 'update:endereco'],

    props: {
        endereco: {
            type: Object,
            required: true
        },
        idManutencao: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dados: {
                endereco_cep: '',
                endereco_cidade: '',
                endereco_logradouro: '',
                endereco_uf: '',
                endereco_numero: '',
                endereco_bairro: '',
                endereco_complemento: ''
            },
            estadoOptions: []
        }
    },

    watch: {
        'dados.endereco_cep': function(value) {
            this.buscaCEP(value)
        },

        dados: {
            deep: true,
            handler(value) {
                this.$emit('update:endereco', value)
            }
        },

        endereco: {
            deep: true,
            handler(value) {
                this.dados = value
            }
        }
    },

    mounted() {
        this.carregarEstados()
    },

    methods: {
        validaCampos() {
            this.$refs.endereco_cep.validate()
            this.$refs.endereco_cidade.validate()
            this.$refs.endereco_logradouro.validate()
            this.$refs.endereco_numero.validate()
            this.$refs.endereco_bairro.validate()
            this.$refs.endereco_uf.validate()

            const errors = [
                this.$refs.endereco_cep.hasError,
                this.$refs.endereco_cidade.hasError,
                this.$refs.endereco_logradouro.hasError,
                this.$refs.endereco_numero.hasError,
                this.$refs.endereco_bairro.hasError,
                this.$refs.endereco_uf.hasError
            ]

            if (errors.includes(true)) {
                return false
            }
            return true
        },

        buscaCEP(value) {
            const cep = value.replace(/[^\d]+/g, '')
            if (cep.length === 8) {
                this.showLoading({ message: 'Buscando dados do CEP! Por favor, Aguarde!' })
                CEPService.getCep(cep)
                    .then(({ data }) => {
                        this.dados = {
                            ...this.dados,
                            endereco_logradouro: data.logradouro ? String(data.logradouro).toLowerCase().replace(
                                data.logradouro.substr(0, data.logradouro.indexOf(' ')).toLowerCase(), '').substring(0, 41).trim() : '',
                            endereco_bairro: data.bairro ? String(data.bairro).substring(0, 40) : '',
                            endereco_cidade: data.cidade ? String(data.cidade).substring(0, 30) : '',
                            endereco_uf: data.estado,
                        }
                        this.hideLoading()
                    })
                    .catch(e => {
                        this.hideLoading()
                        console.log(e);
                        if (e.response.status === 404) {
                            this.warningMessage({
                                title: 'Ops, algo de errado aconteceu!',
                                message: 'CEP não encontrado'
                            })
                        } else {
                            this.warningMessage({
                                title: 'Ops, algo de errado aconteceu!',
                                message: 'Não foi possível autocompletar os campos. Preencha o endereço manualmente.'
                            })
                        }
                    })
            }
        },

        carregarEstados() {
            this.showLoading({ message: 'Buscando estados, Aguarde!' })
            CEPService.getEstados()
                .then(({ data }) => {
                    this.hideLoading()
                    this.estadoOptions = []
                    this.estadoOptions = [
                        ...data.map(el => ({
                            value: el.sigla,
                            label: el.sigla
                        }))
                    ]
                })
                .catch(e => {
                    this.hideLoading()
                    console.log(e)
                })
        },

        proximo() {
            if (this.validaCampos()) {
                this.$emit('proximo')
            }
        },

        voltar() {
            this.$emit('voltar')
        }
    }
}

</script>