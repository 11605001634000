import Services from './services'

class DominioService extends Services {
    constructor() {
        super({
            url: '/dominio',
            config: {
                baseURL: process.env.VUE_APP_API_PROPOSTA_CREDITO,
                headers: {
                    Authorization: process.env.VUE_APP_API_PROPOSTA_CREDITO_TOKEN
                },
                timeout: 60 * 1000
            }
        })
    }
}

export default new DominioService()
