<template>
    <div>
        <!-- Nesta tela buscar o modo de validaçao do contrato
            -pode ser com consulta ao scpc/serasa
            -somente a manutençao
            -consulta scpc e serasa + manutençao cadastro
        -->
        <div class="row justify-center q-ma-sm">
            <div style="width: 100%; max-width: 1200px;">
                <q-stepper
                    v-if="true"
                    flat
                    id="stepper"
                    ref="stepper"
                    v-model="step"
                    color="primary"
                    :no-header-navigation="true"
                    :vertical="isMobile || mxScreen_IsLowResolution"
                    alternative-labels
                    contractable
                    animated
                >
                    <q-step
                        id="stepperContato"
                        name="Contato"
                        icon="phone"
                        active-icon="phone"
                        done-icon="done_all"
                        title="Dados de contato"
                        :order="1"
                    >
                        <step-contato
                            v-model:contato="contato"
                            :id-manutencao="id_manutencao"
                            @proximo="proximoContato"
                        />
                    </q-step>
                    <q-step
                        id="stepperToken"
                        name="Token"
                        icon="key"
                        active-icon="key"
                        done-icon="done_all"
                        title="Token"
                        :order="2"
                    >
                        <step-token
                            v-model:token="token"
                            :telefone-celular="contato.telefone_celular"
                            :id-manutencao="id_manutencao"
                            :tempo-reenvio-sms="tempoReenviarToken"
                            @proximo="proximoToken"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        id="stepperAtualizacaoEndereco"
                        name="AtualizacaoEndereco"
                        icon="home"
                        active-icon="home"
                        done-icon="done_all"
                        title="Atualização de Endereço"
                        :order="3"
                    >
                        <step-atualizacao-endereco
                            v-model:endereco="endereco"
                            :id-manutencao="id_manutencao"
                            @proximo="proximoEndereco"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        id="stepperAtualizacaoRenda"
                        name="AtualizacaoRenda"
                        icon="attach_money"
                        active-icon="attach_money"
                        done-icon="done_all"
                        title="Atualização de Renda"
                        :order="4"
                    >
                        <step-atualizacao-renda
                            v-model:renda="renda"
                            :id-manutencao="id_manutencao"
                            @proximo="proximoRenda"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        id="stepperAnalise"
                        name="Analise"
                        icon="account_balance"
                        active-icon="account_balance"
                        done-icon="done_all"
                        title="Análise"
                        :order="5"
                    >
                        <step-analise
                            :status="statusManutencao"
                            :processando-motor="processandoMotor"
                            :tempo-tentativa-manutencao="tempoTentativaManutencao"
                            :tempo-reprocessar-manutencao="tempoReprocessarManutencao"
                            :observacao-loja="observacaoLoja"
                            @reprocessar="processarMotor"
                        />
                    </q-step>
                </q-stepper>
            </div>
        </div>
        <modal-termo-privacidade
            ref="modalTermo"
            id="TermoPrivacidade"
            @termoAceito="termoAceito"
        />
    </div>
</template>

<script>

import StepContato from '@/components/ManutencaoCliente/steps/contato.vue'
import StepToken from '@/components/ManutencaoCliente/steps/token.vue'
import StepAnalise from '@/components/ManutencaoCliente/steps/analise.vue'
import StepAtualizacaoEndereco from '@/components/ManutencaoCliente/steps/atualizacaoEndereco.vue'
import StepAtualizacaoRenda from '@/components/ManutencaoCliente/steps/atualizacaoRenda.vue'
import ModalTermoPrivacidade from '@/components/ManutencaoCliente/modalTermoPrivacidade.vue'
import ManutencaoClienteService from '@/services/manutencao'
import ParametroPropostaService from '@/services/parametrosProposta'
import MixinMessages from '@/mixins/mixinMessages'
import store from '@/store/index'
import login from '@/plugins/login'
import moment from 'moment'
import { mixinScreen } from 'lins-mixins'

const MANUTENCAO_STATUS_AGUARDANDO_ANALISE = 3
const MANUTENCAO_STATUS_EM_ANALISE = 4 
const MANUTENCAO_STATUS_AGUARDANDO_MOTOR = 5 
const MANUTENCAO_STATUS_REPROVADO_AUTOMATICAMENTE = 6
const MANUTENCAO_STATUS_REPROVADO_MESA = 7
const MANUTENCAO_STATUS_AGUARDANDO_INTEGRAR = 8

export default {
    name: 'ManutencaoCliente',

    components: {
        StepContato,
        StepToken,
        StepAtualizacaoRenda,
        StepAtualizacaoEndereco,
        StepAnalise,
        ModalTermoPrivacidade
    },

    mixins: [
        mixinScreen,
        MixinMessages
    ],

    data() {
        return {
            step: 'Contato',
            id_manutencao: null,
            cpf: store.getters.getUsuarioLogado.cpf,
            statusManutencao: null,
            statusAnalise: null,
            tempoReenviarToken: 1,
            tempoTentativaManutencao: 0,
            tempoReprocessarManutencao: 0,
            contato: {
                telefone_celular: null,
                email: null,
            },
            token: null,
            token_validado: false,
            endereco: {
                endereco_cep: null,
                endereco_cidade: null,
                endereco_logradouro: null,
                endereco_uf: null,
                endereco_numero: null,
                endereco_bairro: null,
                endereco_complemento: null
            },
            renda: {
                renda_mensal: null,
                empresa_admissao: null,
                id_natureza_profissional: null,
                empresa_nome: null,
                empresa_cidade: null,
                empresa_telefone: null,
                empresa_funcao: null
            },
            statusAtualizacaoCadastro: [0, 2, 3, 7],
            statusAtualizacaoB2E: [1],
            processandoMotor: false,
            observacaoLoja: null
        }
    },

    computed: {
        isMobile() {
            return this.$q.platform.is.mobile
        },

        usuario() {
            return login.usuarioLogado()
        },

        rede() {
            return String(process.env.VUE_APP_EMPRESA).toLowerCase() === 'pomp' ? 1 : 4
        },
    },

    mounted() {
        this.buscaDados()
    },

    methods: {
        buscaDados() {
            Promise.all([
                ParametroPropostaService.getParametro('MANUTENCAO.TEMPO_REENVIAR_TOKEN'),
                ParametroPropostaService.getParametro('MANUTENCAO.TEMPO_TENTATIVA'),
                ParametroPropostaService.getParametro('MANUTENCAO.TEMPO_BOTAO_REPROCESSAR'),
                ManutencaoClienteService.get({
                    id_cliente: this.usuario.id,
                    rede: this.rede,
                    status__in: '0,3,4,5,6,7',
                    fields: 'id,status,analise_credito,updated_at'
                })
            ])
            .then(([{ data: tempoReenviarToken }, { data: tempoTentativa }, { data: tempoReprocessar }, { data: manutencoes}]) => {
                    this.tempoReenviarToken = parseInt(tempoReenviarToken[0].valor)
                    this.tempoTentativaManutencao = parseInt(tempoTentativa[0].valor)
                    this.tempoReprocessarManutencao = parseInt(tempoReprocessar[0].valor)
                    this.validaManutencao(manutencoes)
                })
                .catch(e => {
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Não foi possivel carregar os parametros ou a manutenção. Contate a central de credito',
                        error: e
                    })
                    this.$router.push('/')
                })
        },

        proximoContato() {
            this.patchManutencao({
                email: this.contato.email ? this.contato.email.toLowerCase() : null,
                telefone_celular: this.contato.telefone_celular.replace(/[^0-9]/g,'')
            })
        },

        diferencaHoras(data) {
            const now = moment();
            const date = moment(data);
            return Math.abs(date.diff(now, 'hours'));
        },

        validaManutencao(manutencoes) {
            if (manutencoes.length > 0) {
                const manutencao_inicio = manutencoes.filter(manutencao => manutencao.status === 0)
                const manutencao_analise = manutencoes.filter(manutencao => [
                    MANUTENCAO_STATUS_AGUARDANDO_ANALISE,
                    MANUTENCAO_STATUS_EM_ANALISE,
                    MANUTENCAO_STATUS_AGUARDANDO_MOTOR,
                    MANUTENCAO_STATUS_REPROVADO_AUTOMATICAMENTE,
                    MANUTENCAO_STATUS_REPROVADO_MESA
                ].includes(manutencao.status))
                if (manutencao_inicio.length > 0) {
                    this.validaManutencaoInicio(manutencao_inicio[0])
                } else if (manutencao_analise.length > 0) {
                    this.validaManutencaoAnalise(manutencao_analise[manutencao_analise.length - 1])
                }
            } else {
                this.$refs.modalTermo.open()
            }
        },

        validaManutencaoInicio(manutencao) {
            this.id_manutencao = manutencao.id
            this.statusManutencao = manutencao.status
            this.statusAnalise = manutencao.analise_credito
            if (this.statusAtualizacaoCadastro.includes(this.statusAnalise)) {
                this.step = 'Contato'
                this.hideLoading()
            } else if (this.statusAtualizacaoB2E.includes(this.statusAnalise)) {
                this.showLoading({ message: 'Processando os dados da manutenção! Por favor, Aguarde!' })
                this.processarMotor(() => {
                    this.step = 'Analise'
                    this.hideLoading()
                })
            }
        },

        validaManutencaoAnalise(manutencao) {
            if ([MANUTENCAO_STATUS_REPROVADO_AUTOMATICAMENTE, MANUTENCAO_STATUS_REPROVADO_MESA].includes(manutencao.status) &&
                this.diferencaHoras(manutencao.updated_at) >= this.tempoTentativaManutencao
            ) {
                this.$refs.modalTermo.open()
            } else {
                this.id_manutencao = manutencao.id
                this.statusManutencao = manutencao.status
                this.step = 'Analise'
                this.hideLoading()
            }
        },

        getDadosManutencao() {
            this.showLoading({ message: 'Carregando os dados! Por favor, Aguarde!' })
            ManutencaoClienteService.getByID(this.id_manutencao, {
                fields: 'status,analise_credito,eventos'
            })
                .then(({ data}) => {
                    this.statusManutencao = data.status
                    this.statusAnalise = data.analise_credito
                    this.observacaoLoja = data.eventos[0].mensagem.observacao_loja
                    if (this.statusAnalise === 1) {
                        this.step = 'Analise'
                    }
                })
                .catch(() => {
                    this.errorMessage({ 
                        title:'Ops! Não foi possivel carregar os dados do usuario',
                        message:"Não foi possivel carregar os dados do usuario na api. Contate a central de credito",
                    })
                })
                .finally(() => {
                    this.hideLoading()
                })
        },

        proximoToken() {
            if (!this.token_validado) {
                this.showLoading({ message: 'Validando o Token! Por favor, Aguarde!' })
                ManutencaoClienteService
                    .postValidaToken(this.id_manutencao, { token: String(this.token || "").padEnd(6, '0') })
                    .then(() => {
                        this.token_validado = true
                        this.proximoStep()
                    }).catch((error) => {
                        this.errorMessage({ 
                            title:'Ops! Algo de errado aconteceu!',
                            message: 'Verifique o token e tente novamente!',
                            error: error
                        })
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            } else {
                this.proximoStep()
            }
        },

        proximoEndereco() {
            this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
            this.patchManutencao({
                ...this.endereco,
                endereco_uf: this.endereco.endereco_uf.value ? this.endereco.endereco_uf.value : this.endereco.endereco_uf,
                endereco_complemento: this.endereco.endereco_complemento ? this.endereco.endereco_complemento : null
            })
        },

        proximoRenda() {
            this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
            this.patchManutencao({
                ...this.renda,
                empresa_telefone: this.renda.empresa_telefone ? this.renda.empresa_telefone.replace(/[^0-9]/g, '') : "",
                empresa_cep: this.renda.empresa_cep ? this.renda.empresa_cep : '',
                empresa_uf: this.renda.empresa_uf.value ? this.renda.empresa_uf.value : this.renda.empresa_uf ? this.renda.empresa_uf : "",
                empresa_cidade: this.renda.empresa_cidade ? this.renda.empresa_cidade : "",
                empresa_endereco: this.renda.empresa_endereco ? this.renda.empresa_endereco : "",
                id_natureza_profissional: this.renda.id_natureza_profissional.value
            }, (next) => {
                this.processarMotor(next)
            })
        },

        patchManutencao(dados, onPatchDone = null) {
            this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
            ManutencaoClienteService
                .patch(this.id_manutencao, dados)
                    .then(() => {
                        if (onPatchDone) {
                            onPatchDone(this.proximoStep)
                        } else {
                            this.proximoStep()
                        }
                    }).catch(err => {
                        this.hideLoading()
                        this.errorMessage({
                            title: 'Ops, algo de errado aconteceu!',
                            message: 'Erro atualizar a manutençao de cliente',
                            error: err
                        })
                    })
        },

        termoAceito(id_manutencao) {
            this.id_manutencao = id_manutencao

            this.showLoading({ message: 'Carregando os dados! Por favor, Aguarde!' })
            ManutencaoClienteService.getByID(this.id_manutencao, {
                fields: 'analise_credito,status'
            })
                .then(({ data }) => {
                    const status_analise_credito = data.analise_credito
                    if (this.statusAtualizacaoCadastro.includes(status_analise_credito)) {
                        this.step = 'Contato'
                        this.hideLoading()
                    } else if (this.statusAtualizacaoB2E.includes(status_analise_credito)) {
                        this.showLoading({ message: 'Processando os dados da manutenção! Por favor, Aguarde!' })
                        this.processarMotor(() => {
                            this.step = 'Analise'
                            this.hideLoading()
                        })
                    }
                })
                .catch(() => {
                    this.hideLoading()
                    this.errorMessage({ 
                        title:'Ops! Não foi possivel carregar os dados do usuario',
                        message:"Não foi possivel carregar os dados do usuario na api. Contate a central de credito",
                    })
                })
        },

        processarMotor(onProcessarMotorDone = null) {
            this.showLoading({ message: 'Processando os dados da Manutenção! Por favor, Aguarde!' })
            this.processandoMotor = true
            ManutencaoClienteService.postProcessarMotor(this.id_manutencao)
                .then(() => {
                    this.getDadosManutencao()
                    if (onProcessarMotorDone) {
                        onProcessarMotorDone()
                    }
                })
                .catch(error => {
                    this.statusManutencao = MANUTENCAO_STATUS_AGUARDANDO_INTEGRAR
                    this.errorMessage({ 
                        title:'Ops! Algo de errado aconteceu',
                        message:"Não foi possível concluir a atualização de cadastro. Contate a central de credito",
                        erro: error
                    })
                })
                .finally(() => {
                    this.processandoMotor = false
                    this.hideLoading()
                })
        },

        proximoStep() {
            this.hideLoading()
            this.$refs.stepper.next()
        },

        voltarStep() {
            this.$refs.stepper.previous()
        }
    }
}
</script>

<style>
.q-top-space {
    margin-top: 10px
}
</style>
