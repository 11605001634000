import Services from './services'

class ManutencaoClienteService extends Services {
    constructor() {
        super({
            url: '/manutencao',
            config: {
                baseURL: process.env.VUE_APP_API_PROPOSTA_CREDITO,
                headers: {
                    'api-version': process.env.VUE_APP_API_PROPOSTA_CREDITO_VERSION,
                    authorization: process.env.VUE_APP_API_PROPOSTA_CREDITO_TOKEN,
                    'Content-Type': 'application/json'
                },
                timeout: 60 * 1000
            }
        })
    }

    postValidaToken(id_manutencao, body) {
        return this.axios.post(`${this.url}/${id_manutencao}/validar-token`, body)
    }

    postReenviaToken(id_manutencao) {
        return this.axios.post(`${this.url}/${id_manutencao}/reenviar-token`, {})
    }

    postProcessarMotor(idManutencao){
        return this.axios.post(`${this.url}/${idManutencao}/processar-motor`, {})
    }
}

export default new ManutencaoClienteService()